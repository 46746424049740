import { Component } from '@angular/core';
import { OneSignal } from 'onesignal-ngx';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  constructor(private oneSignal:OneSignal){
    this.oneSignalInitialize();
  }

  async oneSignalInitialize(){
     await this.oneSignal.init({
      appId:'dbc88fbb-5a63-4cc8-8884-5bb495df3dcd',
      // allowLocalhostAsSecureOrigin:true
      // serviceWorkerPath: 'src/oneSignalSDKWorker.js'

    })
  }

  ngOnInit() {
    const body = document.querySelector('body') as HTMLElement;
    body.classList.remove('mat-typography');
  }
}
